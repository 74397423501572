import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  // {
  //   id: "home",
  //   title: "Home",
  //   translate: "MENU.HOME",
  //   type: "item",
  //   icon: "home",
  //   url: "home",
  // },
  // {
  //   id: "sample",
  //   title: "Sample",
  //   translate: "MENU.SAMPLE",
  //   type: "item",
  //   icon: "file",
  //   url: "sample",
  // },
  // {
  //   id: "product",
  //   title: "product",
  //   translate: "MENU.PRODUCT.PRODUCT",
  //   type: "collapsible",
  //   icon: "package",
  //   children: [
  //     {
  //       id: "product-list",
  //       title: "Product",
  //       translate: "MENU.PRODUCT.PRODUCT",
  //       type: "item",
  //       icon: "circle",
  //       url: "pages/product-management/product",
  //     },
  //     {
  //       id: "category-list",
  //       title: "Category",
  //       translate: "MENU.PRODUCT.CATEGORY",
  //       type: "item",
  //       icon: "circle",
  //       url: "pages/product-management/category",
  //     },
  //   ],
  // },
  {
    id: "Booking",
    title: "Booking",
    translate: "MENU.BOOKING.BOOKING",
    type: "item",
    icon: "truck",
    url: "pages/booking-management/booking-list",
    // children: [
    //   {
    //     id: "booking-list",
    //     title: "Booking",
    //     translate: "MENU.BOOKING.BOOKING",
    //     type: "item",
    //     icon: "circle",
    //     url: "pages/booking-management/booking-list",
    //   },
    // ],
  },

  {
    id: "Reward",
    title: "Redeem",
    translate: "MENU.REDEEM.REWARD",
    type: "collapsible",
    icon: "gift",
    children: [
      {
        id: "reward",
        title: "Market Place",
        translate: "MENU.REDEEM.REWARD",
        type: "item",
        icon: "circle",
        url: "pages/redeem-management/reward",
      },
      {
        id: "reward-exchange-list",
        title: "Reward Exchange",
        translate: "MENU.REDEEM.REWARD_EXCHANGE",
        type: "item",
        icon: "circle",
        url: "pages/redeem-management/reward-exchange",
      },
    ],
  },
  {
    id: "Customer",
    title: "Customer",
    translate: "MENU.CUSTOMER.CUSTOMER",
    type: "collapsible",
    icon: "user",
    children: [
      {
        id: "customer-list",
        title: "Customers",
        translate: "MENU.CUSTOMER.CUSTOMER",
        type: "item",
        icon: "circle",
        url: "pages/customer-management/customer",
      },
      {
        id: "driver-list",
        title: "Drivers",
        translate: "MENU.CUSTOMER.DRIVER",
        type: "item",
        icon: "circle",
        url: "pages/customer-management/driver",
      },
      // {
      //   id: "customer-point",
      //   title: "Points",
      //   translate: "MENU.CUSTOMER.POINT",
      //   type: "item",
      //   icon: "circle",
      //   url: "pages/customer-management/customer-point",
      // },
    ],
  },
  {
    id: "administrator",
    title: "Administrator",
    translate: "MENU.ADMINISTRATOR.USER",
    type: "collapsible",
    icon: "user",
    children: [
      {
        id: "list",
        title: "Users",
        translate: "MENU.ADMINISTRATOR.USER",
        type: "item",
        icon: "circle",
        url: "pages/administrator",
      },
      // {
      //   id: "role",
      //   title: "Role",
      //   translate: "MENU.ADMINISTRATOR.ROLE",
      //   type: "item",
      //   icon: "circle",
      //   url: "pages/user-management/role-list",
      // },
    ],
  },

  // Report
  {
    id: "report",
    title: "Report",
    translate: "MENU.REPORT.REPORT",
    type: "collapsible",
    icon: "book",
    children: [
      {
        id: "list",
        title: "Booking",
        translate: "MENU.REPORT.BOOKING",
        type: "item",
        icon: "circle",
        url: "pages/reports/booking",
      },
      // {
      //   id: "role",
      //   title: "Role",
      //   translate: "MENU.ADMINISTRATOR.ROLE",
      //   type: "item",
      //   icon: "circle",
      //   url: "pages/user-management/role-list",
      // },
    ],
  },

  // Selling Product
  {
    id: "selling-product",
    title: "Selling Product",
    translate: "MENU.SELLING_PRODUCT.SELLING_PRODUCT",
    type: "collapsible",
    icon: "box",
    children: [
      {
        id: "category",
        title: "Category",
        translate: "MENU.SELLING_PRODUCT.CATEGORY",
        type: "item",
        icon: "circle",
        url: "pages/selling-product-management/category/list",
      },
      {
        id: "product",
        title: "Product",
        translate: "MENU.SELLING_PRODUCT.PRODUCT",
        type: "item",
        icon: "circle",
        url: "pages/selling-product-management/product/list",
      },
    ],
  },

  {
    id: "Setting",
    title: "Settings",
    translate: "MENU.SETTING.SETTING",
    type: "collapsible",
    icon: "settings",
    children: [
      {
        id: "price-setup",
        title: "Price Setup",
        translate: "MENU.SETTING.PRICE_SETUP",
        type: "item",
        icon: "dollar-sign",
        url: "pages/settings/price-setup",
      },
      {
        id: "delivery-option",
        title: "Delivery Option",
        translate: "MENU.SETTING.DELIVERY_OPTION",
        type: "item",
        icon: "truck",
        url: "pages/settings/delivery-option",
      },
      {
        id: "booking-type",
        title: "Booking & Exchange Price Type",
        translate: "MENU.SETTING.BOOKING_TYPE",
        type: "item",
        icon: "truck",
        url: "pages/settings/booking-type",
      },
      {
        id: "slide-show",
        title: "SlideShow",
        translate: "MENU.SETTING.SLIDE_SHOW",
        type: "item",
        icon: "sidebar",
        url: "pages/settings/slideshow",
      },
      {
        id: "notification",
        title: "Notifications",
        translate: "MENU.SETTING.NOTIFICATION",
        type: "item",
        icon: "bell",
        url: "pages/settings/notifications",
      },
    ],
  },
];
