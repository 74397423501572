export const locale = {
  lang: "en",
  data: {
    MENU: {
      HOME: "Home",
      PRODUCT: {
        PRODUCT: "Products",
        PRODUCT_LIST: "Product",
        CATEGORY: "Category",
      },
      SETTING: {
        SETTING: "Setting",
        PRICE_SETUP: "Price Setup",
        SLIDE_SHOW: "Slide Show",
        BOOKING_TYPE: "Booking Type",
        DELIVERY_OPTION: "Delivery Option",
        NOTIFICATION: "Notification",
      },
      REDEEM: {
        REWARD: "Reward",
        REWARD_EXCHANGE: "Reward Exchange",
      },
      BOOKING: {
        BOOKING: "Booking",
        // ROLE: "Role",
      },
      REPORT: {
        REPORT: "Report",
        BOOKING: "Booking",
      },
      SELLING_PRODUCT: {
        SELLING_PRODUCT: "Selling Product",
        CATEGORY: "Category",
        PRODUCT: "Product",
      },
      CUSTOMER: {
        CUSTOMER: "Customer",
        WALLET: "Wallet",
        POINT: "Point",
        DRIVER: "Driver",
      },
      ADMINISTRATOR: {
        USER: "User",
        ROLE: "Role",
      },
    },
  },
};
