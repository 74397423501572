import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: "root",
})
export class LocalService {
  key = "884BF9859CDEECFFF628FE2ACA4AB";
  hex =
    "2c8a787463a722ca9e10ed1d022ee764c4ce9200981a90d3ca81f38b51e35618b6f70f0df16ff4477b8d8b1423b97dc901b1d611efeb13a0bb8fab5c4ff9d6ee30ed036101f17e572b9d2f25a707e998";
  constructor() {}

  public saveData(key: string, value: string) {
    localStorage.setItem(key, this.encrypt(value).toString());
  }

  public getData(key: string) {
    let data = localStorage.getItem(key) || "";
    return this.decrypt(data);
  }
  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }

  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, this.key).toString();
  }

  private decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, this.key).toString(
      CryptoJS.enc.Utf8
    );
  }
}
