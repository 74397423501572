import { NgModule } from "@angular/core";

import { FilterPipe } from "@core/pipes/filter.pipe";

import { InitialsPipe } from "@core/pipes/initials.pipe";

import { SafePipe } from "@core/pipes/safe.pipe";
import { StripHtmlPipe } from "@core/pipes/stripHtml.pipe";
import { StatusPipe } from "./status.pipe";

@NgModule({
  declarations: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, StatusPipe],
  imports: [],
  exports: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, StatusPipe],
})
export class CorePipesModule {}
